<div class="container">
    <div class="section-title">
        <h2>Awsome Features</h2>
        <div class="bar"></div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-sun"></i>
                </div>
                <h3>High Resolution</h3>
                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-lightbulb"></i>
                </div>
                <h3>Unique Design</h3>
                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-desktop"></i>
                </div>
                <h3>Full Responsive</h3>
                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-code"></i>
                </div>
                <h3>Clean Codes</h3>
                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-eye"></i>
                </div>
                <h3>Retina Ready</h3>
                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-camera"></i>
                </div>
                <h3>Unlimited Features</h3>
                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
            </div>
        </div>
    </div>
</div>